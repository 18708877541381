var exports = {};
exports = {
  A: {
    A: {
      "1": "A B",
      "2": "J D E F rB"
    },
    B: {
      "1": "C K L G M N O",
      "516": "P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "132": "GB HB IB JB KB LB MB hB NB iB OB PB U",
      "164": "0 1 2 3 4 5 6 7 8 9 sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB tB uB",
      "516": "QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c",
      "1028": "d e f g T h H kB"
    },
    D: {
      "420": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB",
      "516": "EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "A B C K L G mB eB fB 2B 3B 4B nB oB 5B",
      "132": "F 1B",
      "164": "D E 0B",
      "420": "I i J xB lB yB zB"
    },
    F: {
      "1": "C eB pB AC fB",
      "2": "F B 6B 7B 8B 9B",
      "420": "0 G M N O j k l m n o p q r s t u v w x y z",
      "516": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "1": "IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "132": "GC HC",
      "164": "E EC FC",
      "420": "lB BC qB CC DC"
    },
    H: {
      "1": "VC"
    },
    I: {
      "420": "gB I WC XC YC ZC qB aC bC",
      "516": "H"
    },
    J: {
      "420": "D A"
    },
    K: {
      "1": "C eB pB fB",
      "2": "A B",
      "516": "U"
    },
    L: {
      "516": "H"
    },
    M: {
      "516": "T"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "dC eC fC gC hC mB iC jC kC lC mC nC",
      "420": "I"
    },
    Q: {
      "132": "oC"
    },
    R: {
      "132": "pC"
    },
    S: {
      "164": "qC"
    }
  },
  B: 4,
  C: "CSS3 Multiple column layout"
};
export default exports;